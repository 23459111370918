import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';

import axios from 'axios';

function SuccessAllCandidateLargeContent() {

    let [successalllist, setSuccessalllist]=useState([]);

    // const baseurl="https://www.safalata.in/safalatalara";
    // const imgpath="./studportalnew";
    // const imgpath="./studportalnew";
    const imgpath="https://safalata.in/safalatalara";

    async function successallcandidate() {

        // var resp=await axios.get({baseurl}+"/api/successallcandidate");
        var resp=await axios.get("https://www.safalata.in/safalatalara/api/successallcandidate");
        var data=resp.data;
        setSuccessalllist(data);

    }

    let [intervalspeed,setIntervalspeed]=useState(0);
    let e=0;
    useEffect(()=>{
        successallcandidate();
        
        setInterval(()=>{
            if (e<=7000) {
                setIntervalspeed(e);
                console.log("intervalspeed ==>",e);
                console.log("e ==>",e);

                window.scrollTo({
                    top: e, 
                    left: 0,
                    behavior: 'smooth' 
                });

                e=parseInt(e)+150;
                setIntervalspeed(e);

            } 

            if (e>6800) { 
                e=0;
                window.scrollTo({
                    top: e, 
                    left: 0,
                    behavior: 'smooth' 
                });
            }
            // else {
            //     e=0;
            //     // setIntervalspeed(e);
            //     // window.scrollTo({
            //     //     top: e, 
            //     //     left: 0,
            //     //     behavior: 'smooth' 
            //     // });
            // }
            // else if (e<=10) {
            //     e=7000;
            //     setIntervalspeed(e);
            //     window.scrollTo({
            //         top: e, 
            //         left: 0,
            //         behavior: 'smooth' 
            //     });
            // }
            
        },[4000]);

        
    },[])

    return(
        <>
        <section id="team" className="team section-bg">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <h2>Our <strong>Success All Candidates</strong></h2>
                </div>

                <div className="row">

                    {successalllist.map((e)=>
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={e.id}>
                        <div className="member" data-aos="fade-up">
                        <div className="member-img">
                            <img src={imgpath+"/uploadpic/"+e.image} style={{borderRadius: '10px'}} className="img-fluid" alt="" />
                            
                        </div>
                        <div className="member-info">
                        <h4>
                        <span style={{color: '#9e0936', fontSize: 'large', fontWeight: '900'}}>{e.name}</span>
                        </h4>
                        <span style={{fontSize: 'medium', color: '#05006b', fontWeight: '700'}}>{e.designation}</span>
                        <span style={{fontSize: 'medium', color: '#000', fontWeight: '700'}}>{e.department}</span>
                        {e.city!=null?
                        <h4>
                        <span style={{color: '#000', fontSize: 'medium', paddingTop: '7px'}}>{e.city}</span>
                        </h4>
                        :
                        ''
                        }
                            {/* <span>{e.batch_name}</span> */}
                        </div>
                        </div>
                    </div>
                    )}

                </div>

            </div>
        </section>
        </>
    );
}
export default SuccessAllCandidateLargeContent;