//import {Link} from 'react-router-dom';

import React, { useEffect, useState } from "react";
// import HeroCarousel from "react-hero-carousel";


import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Herosliderreact () {

    const slider1={
        backgroundImage: 'url(./assets/img/slider/banner1.jpg)'
    };
    const slider2={
        backgroundImage: 'url(./assets/img/slider/banner2.jpg)'
    };
    const slider3={
        backgroundImage: 'url(./assets/img/slider/banner3.jpg)'
    };
    const slider4={
        backgroundImage: 'url(./assets/img/slider/banner4.jpg)'
    };
    const slider5={
        backgroundImage: 'url(./assets/img/slider/banner5.jpg)'
    };
    const slider6={
        backgroundImage: 'url(./assets/img/slider/banner6.jpg)'
    };
    const slider7={
        backgroundImage: 'url(./assets/img/slider/banner7.jpg)'
    };

    let [sliderimg1,setSliderimg1]=useState('');
    let [sliderimg2,setSliderimg2]=useState('');
    let [sliderimg3,setSliderimg3]=useState('');
    let [sliderimg4,setSliderimg4]=useState('');
    let [sliderimg5,setSliderimg5]=useState('');
    let [sliderimg6,setSliderimg6]=useState('');
    let [sliderimg7,setSliderimg7]=useState('');
    
    useEffect(()=>{
        setSliderimg1('./assets/img/slider/banner1.jpg');
        setSliderimg2('./assets/img/slider/banner2.jpg');
        setSliderimg3('./assets/img/slider/banner3.jpg');
        setSliderimg4('./assets/img/slider/banner4.jpg');
        setSliderimg5('./assets/img/slider/banner5.jpg');
        setSliderimg6('./assets/img/slider/banner6.jpg');
        setSliderimg7('./assets/img/slider/banner7.jpg');
    },[])

    return(
        <>
        {/* <HeroCarousel>
            <img
            src="https://placem.at/places?w=1920&h=1080&seed=1&txt=1"
            width="100%"
            height="100%"
            />
            <img
            src="https://placem.at/places?w=1920&h=1080&seed=2&txt=2"
            width="100%"
            height="100%"
            />
            <img
            src="https://placem.at/places?w=1920&h=1080&seed=2&txt=2"
            width="100%"
            height="100%"
            />
        </HeroCarousel> */}

        <section id="hero11">
            <div id="heroCarousel11" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

            {/* <div className="carousel-inner" role="listbox"> */}
            <div className="carousel-wrapper">
                {/* <HeroCarousel showArrows={true}> */}
                <Carousel>

                    {/* <div className="carousel-item active" style={slider1}>
                    <div className="carousel-container">
                        <div className="carousel-content animate__animated animate__fadeInUp">
                        <h2>Welcome to <span>Company</span></h2>
                        <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                        <div className="text-center"><Link to="" className="btn-get-started">Read More</Link></div>
                        </div>
                    </div>
                    </div> */}

                    {/* <div className="carousel-item" style={slider2}>
                    <div className="carousel-container">
                        <div className="carousel-content animate__animated animate__fadeInUp">
                        <h2>Lorem Ipsum Dolor</h2>
                        <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                        <div className="text-center"><Link to="" className="btn-get-started">Read More</Link></div>
                        </div>
                    </div>
                    </div> */}

                    {/* <div className="carousel-item" style={slider3}>
                    <div className="carousel-container">
                        <div className="carousel-content animate__animated animate__fadeInUp">
                        <h2>Sequi ea ut et est quaerat</h2>
                        <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                        <div className="text-center"><Link to="" className="btn-get-started">Read More</Link></div>
                        </div>
                    </div>
                    </div> */}
                    <img
                    src={sliderimg1}
                    />
                    <img
                    src={sliderimg2}
                    />
                    <img
                    src={sliderimg3}
                    />
                    <img
                    src={sliderimg4}
                    />
                    <img
                    src={sliderimg5}
                    />
                    <img
                    src={sliderimg6}
                    />
                    <img
                    src={sliderimg7}
                    />
                </Carousel>


                {/* <div className="carousel-item active" style={slider1}>
                <div className="carousel-container">
                    <div className="carousel-content animate__animated animate__fadeInUp">
                    <h2>Welcome to <span>Company</span></h2>
                    <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                    <div className="text-center"><Link to="" className="btn-get-started">Read More</Link></div>
                    </div>
                </div>
                </div>

                <div className="carousel-item" style={slider2}>
                <div className="carousel-container">
                    <div className="carousel-content animate__animated animate__fadeInUp">
                    <h2>Lorem Ipsum Dolor</h2>
                    <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                    <div className="text-center"><Link to="" className="btn-get-started">Read More</Link></div>
                    </div>
                </div>
                </div>

                <div className="carousel-item" style={slider3}>
                <div className="carousel-container">
                    <div className="carousel-content animate__animated animate__fadeInUp">
                    <h2>Sequi ea ut et est quaerat</h2>
                    <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                    <div className="text-center"><Link to="" className="btn-get-started">Read More</Link></div>
                    </div>
                </div>
                </div> */}

                {/* </HeroCarousel> */}

            </div>

            {/* <Link className="carousel-control-prev" to="#heroCarousel" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
            </Link>

            <Link className="carousel-control-next" to="#heroCarousel" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            </Link> */}

            {/* <ol className="carousel-indicators" id="hero-carousel-indicators"></ol> */}

            </div>
        </section>  


        </>
    );
}