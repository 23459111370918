import {useEffect, useState} from 'react';

import {Link} from 'react-router-dom';

import $ from 'jquery';

function Header() {

    const navtop20={
        paddingTop:'20px'
    };

    let [iconlist,setIconlist]=useState('bi bi-list mobile-nav-toggle');
    let [iconx,setIconx]=useState('bi bi-x mobile-nav-toggle');

    let [activehome, setActivehome]=useState("");
    let [activeabout, setActiveabout]=useState("");
    let [activeadmission, setActiveadmission]=useState("");
    let [activecourse, setActivecourse]=useState("");
    let [activesuccessstory, setActivesuccessstory]=useState("");
    let [activecontact, setActivecontact]=useState("");

    useEffect(()=>{
        if(window.location.pathname=='/') {
            setActivehome("active");
        } else if(window.location.pathname=='/academy') {
            setActiveabout("active");
        } else if(window.location.pathname=='/admission') {
            setActiveadmission("active");
        } else if(window.location.pathname=='/course') {
            setActivecourse("active");
        } else if(window.location.pathname=='/successstory') {
            setActivesuccessstory("active");
        } else if(window.location.pathname=='/contact') {
            setActivecontact("active");
        }
        // setIconlist('bi bi-list mobile-nav-toggle');

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    },[]) 

    return(
        <>
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center">

            <Link to="/" className="logo me-auto me-lg-0">
                <img src="../assets/img/logo.png" alt="" className="img-fluid"/>
            </Link>

            {/* <div className="header-social-links1 d-flex">
                <Link to="#" className="twitter"><i className="bu bi-twitter"></i></Link>
                <Link to="#" className="facebook"><i className="bu bi-facebook"></i></Link>
                <Link to="#" className="instagram"><i className="bu bi-instagram"></i></Link>
                <Link to="#" className="linkedin"><i className="bu bi-linkedin"></i></Link>
            </div> */}

            {/* <nav id="navbar" className="navbar order-last order-lg-0"> */}

            {/* <nav id="navbar" className="navbar">
                <ul className="btnNav1"> */}
                
            <nav id="navbar" className="navbar">
            <ul className="btnNav1">
                {/* <ul> */}
                <li><Link to="/" className={activehome}>Home</Link></li>

                <li className="dropdown"><Link to="#" className={activeabout}><span>About Safalata</span> <i className="bi bi-chevron-down"></i></Link>
                    <ul>
                        <li><Link to="/academy">Academy</Link></li>
                    </ul>
                </li>

                <li><Link to="/admission" className={activeadmission}>Admission</Link></li>
                <li><Link to="/course" className={activecourse}>Course</Link></li>
                <li><Link to="/successstory" className={activesuccessstory}>Success Story</Link></li>
                {/* <li><Link to="blog.html">Blog</Link></li> */}
                <li><Link to="/contact" className={activecontact}>Contact</Link></li>
                <li><Link to="#" className='text text-success' onClick={()=>{
                    window.open("https://studportal.safalata.in/");
                }} style={{fontSize: 'large', marginLeft:'5px',marginRight:'5px'}}>Login</Link></li>
                <li><Link to="/register" className={"text text-danger text-center "} style={{fontSize: 'large', marginLeft:'5px',marginRight:'5px'}}>Register</Link></li>
                </ul>
                {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
                {iconlist?<i className={iconlist} id="btnNav" onClick={()=>{
                    // setIconx('bi bi-x mobile-nav-toggle');
                    setIconx('bi bi-x mobile-nav-toggle');
                    setIconlist('');
                    $(".btnNav1").toggle(200);
                    
                }}></i>:<i className={iconx} id="btnNav" onClick={()=>{
                    setIconlist('bi bi-list mobile-nav-toggle');
                    setIconx('');
                    $(".btnNav1").toggle(10);
                    
                }}></i>}
            </nav>

            <div className="header-social-links">
                <Link to="#" className="twitter"><i className="bu bi-twitter"></i></Link>
                <Link to="#" className="facebook"><i className="bu bi-facebook"></i></Link>
                {/* <Link to="#" className="instagram"><i className="bu bi-instagram"></i></Link>
                <Link to="#" className="linkedin"><i className="bu bi-linkedin"></i></Link> */}
            </div>

            </div>
        </header>
        </>
    );
}
export default Header;