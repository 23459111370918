import {Link} from 'react-router-dom';

import Header from '../include/Header';
//import Heroslider from '../include/Heroslider';
import SectionAbout from '../include/SectionAbout';


import Footer from '../include/Footer';

const margincenter={
    marginLeft: 'auto',
    marginRight: 'auto'
};

const btnsize={
    fontSize: 'x-large'
};

const mgtop={
    marginTop: '112px'
};

const imground={
    borderRadius: '50%',
    width: '200px'
};

function Academy() {
    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Academy</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Academy</li>
                </ol>
                </div>

            </div>
        </section>
        <SectionAbout/>

        <div className="section">
        <div className="container">
            <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-4 mb-5">
                <h2 className="section-title-underline mb-1">
                <span>OUR TEAM</span>
                
                </h2>
                <span className="position mb-1 d-block fs-5 fw-bold text-primary py-2">FOUNDER</span>   
                <div className='fs-6 fw-bold fs-5'>Pushpa Shaw, M.A., B.Ed.</div>
                <span className="position mt-2 mb-1 d-block fs-5 fw-bold text-primary py-2">ADVISOR</span>   
                <div className='fs-6 fw-bold'>Dharmendra Shaw, M.A. (ENGLISH & HINDI), B.ED., PGDPM & IR, PGDT</div>
                <span className="position mt-2 mb-1 d-block fs-5 fw-bold text-primary py-2">FACULTIES</span>   
                <div className='fs-6 py-2 fw-bold'>Soni Shaw, M.A. ( ENGLISH, FRENCH & HINDI)</div>
                <div className='fs-6 py-2 fw-bold'>Rabi Keshari, M.A. (HINDI), B.ED</div>
                <div className='fs-6 py-2 fw-bold'>Ankit Kumar, M.A. (ENGLISH), PGDT</div>
                <div className='fs-6 py-2 fw-bold'>Jyoti Bhagat, M.Sc.</div>
                <div className='fs-6 py-2 fw-bold'>Pankaj Singh, M.A., M.PHIL.</div>
            </div>
            </div>
            <div className="row d-none">
            <div className="col-lg-12 col-md-6 mb-5 mb-lg-5">
                <div className="feature-1 border person text-center">
                    {/* <img src="./assets/img/teacher.jpg" alt="Image" className="img-fluid" style={imground}/> */}
                    <div className="feature-1-content">
                        <h2>Dharmendra Shaw</h2>
                        <span className="position mb-2 d-block">Teacher</span>    
                        <div className="py-0">M.A. (English and Hindi)</div>
                        <div className="py-0">P.G.D.T., B.Ed., P.G.D.P.M &amp; I.R.</div>
                        <div className="py-0">14 Years Experience of Teaching.</div>
                    </div>
                </div>
            </div>
            
            </div>
        </div>
        </div>
        
        <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title" data-aos="fade-up">
                    <h2>About <strong>Our Institute</strong></h2>
                    <p></p>
                </div>

                <div className="row" style={margincenter}>
                {/* <div className="col-lg-3 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div> */}
                <div className="col-lg-12 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon-box iconbox-blue">
                    <h4 className="px-3"><a href="">"भाषा केवल अभिव्यक्ति का माध्यम नहीं, यह विचारों और संस्कारों की धारा है। एक कुशल अनुवादक और राजभाषा अधिकारी वही बनता है, जो इस धारा को सही दिशा में प्रवाहित करना जानता हो। परिश्रम, ज्ञान, और निरंतर अभ्यास ही वह माध्यम हैं, जो आपको इस यात्रा में सफलता की ओर ले जाएंगे।"</a></h4>
                    <h4 className="px-3"><a href="">"Language is not just a medium of expression, it is the bridge of thoughts and values. A skilled translator and Rajbhasha Adhikari are those who can guide this flow with precision and purpose. Hard work, knowledge and continuous practice are the keys to success in this journey."</a></h4>
                    <h4> &nbsp;</h4>
                    <Link to="/academy" className="btn btn-success" style={btnsize}>Read More</Link>
                    </div>
                </div>
                {/* <div className="col-lg-2 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div> */}
                </div>

            </div>
        </section>

        </main>
        <Footer/>
        </>
    );
}
export default Academy;