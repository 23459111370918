import {Link} from 'react-router-dom';

import Header from '../include/Header';
//import Heroslider from '../include/Heroslider';
import Herosliderreact from '../include/Herosliderreact';
import SectionAbout from '../include/SectionAbout';
import SectionCourse from '../include/SectionCourse';
import SuccessCandidate from '../include/SuccessCandidate';

import Footer from '../include/Footer';

import { useEffect } from 'react';

const margincenter={
    marginLeft: 'auto',
    marginRight: 'auto'
};

const btnsize={
    fontSize: 'x-large'
};

function Home() {

    return(
        <>
        {/* Hello */}
        <Header/>
        {/* <Heroslider/> */}
        <Herosliderreact/>
        <SectionAbout/>
        <SectionCourse/>

        <SuccessCandidate/>
        <section id="services" class="services section-bg">
            <div class="container" data-aos="fade-up">

                <div className="section-title" data-aos="fade-up">
                    <h2>About <strong>Our Institute</strong></h2>
                </div>

                <div class="row" style={margincenter}>
                {/* <div class="col-lg-1 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div> */}
                <div class="col-lg-12 col-md-12 d-flex align-items-stretch rounded-lg" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box iconbox-blue">
                    <h4 className="px-3"><a href="">"भाषा केवल अभिव्यक्ति का माध्यम नहीं, यह विचारों और संस्कारों की धारा है। एक कुशल अनुवादक और राजभाषा अधिकारी वही बनता है, जो इस धारा को सही दिशा में प्रवाहित करना जानता हो। परिश्रम, ज्ञान, और निरंतर अभ्यास ही वह माध्यम हैं, जो आपको इस यात्रा में सफलता की ओर ले जाएंगे।"</a></h4>
                    <h4 className="px-3"><a href="">"Language is not just a medium of expression, it is the bridge of thoughts and values. A skilled translator and Rajbhasha Adhikari are those who can guide this flow with precision and purpose. Hard work, knowledge and continuous practice are the keys to success in this journey."</a></h4>
                    <h4> &nbsp;</h4>
                    <Link to="/academy" className="btn btn-success" style={btnsize}>Read More</Link>
                    </div>
                </div>
                {/* <div class="col-lg-1 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div> */}
                </div>

            </div>
        </section>
        <Footer/>
        </>
    );
}
export default Home;